var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      ref: "modal-2",
      staticClass: "modal-sm",
      attrs: { active: _vm.modal, center: true },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "mega-header",
        {
          staticClass: "bg-danger text-white",
          attrs: { title: "Delete rounds" }
        },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v("Confirm that you are sure you want to delete the rounds.")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _vm._l(_vm.rounds, function(id, i) {
            return _c("span", { key: i, staticClass: "badge m-2" }, [
              _vm._v("#" + _vm._s(id))
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "navbar sm-down:flex-column-reverse md-up:justify-content-end"
            },
            [
              _c(
                "mega-button",
                {
                  staticClass: "bg-white sm-down:w-100 md-up:mr-2",
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "bg-danger sm-down:w-100 sm-down:mb-3",
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm")
                    }
                  }
                },
                [_vm._v("DELETE")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }